<template>
  <main-frame>
    <div class="error-page error404">
      <a-result :title="`页面已失效，正在跳转...${count}s`">
        <template #icon>
          <img :src="img">
        </template>
        <template #extra>
          <a-button type="primary" @click="$router.replace(indexPath)">点击返回首页</a-button>
        </template>
      </a-result>
    </div>
  </main-frame>
</template>

<script>
import backToIndexMixin from './backToIndex.mixin'
import img from '@/assets/404.png'

export default {
  mixins: [backToIndexMixin],
  name: 'Error404',
  data() {
    return {
      img,
    }
  },
}
</script>
